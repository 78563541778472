/**
 * Locks / Unlocks scroll of the body
 *
 * @param {bool} lock - Indicator to lock / unlock scroll
 */
function setScroll(lock) {
  const { body } = document;
  if (lock) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'auto';
  }
}

export default setScroll;
