import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import media from '../../styles/media';
import { TextSubtitleBold, TextBody } from '../../styles/fonts';

import ArtDoggo from '../../assets/about-us/art_aldoggo.svg';
import EduDoggo from '../../assets/about-us/edu_aldoggo.svg';

/* STRING */
const meetTitle = 'Meet Aldoggos';
const eduTitle = 'Edu Aldoggo';
const edu1 = 'Hi there! I’m Edu Aldoggo.';
const edu2 =
  'I am an 1 of 1000 NFT created for educational purpose. I can’t wait for you to go through our courses and learn how to adopt me!';
const edu3 = 'We will teach you the basics of blockchain and Algorand, how to buy ALGO';
const edu4 = '(the crypto you will use to buy me!)';
const edu5 = 'with fiat currency, how to setup your personal wallet, and how to buy your first NFT.';
const artTitle = 'Art Aldoggo';
const art1 = 'Hey there! I’m Art Aldoggo.';
const art2 = 'I am an 1 of 1 unique NFT each with their own style and flare.';
const art3 =
  'I’m sure you’ve already seen how cute our Edu Aldoggo is and I’m extra certain that you now want to adopt many Aldoggos!';
const art4 = ' to see our beautiful Art Aldoggos waiting to be adopted by you.';

/* Styled Components */
const MeetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 80px;
  width: 100vw;
  ${media.tablet`
     margin-bottom: 120px;
  `}
  ${media.desktop`
    margin-bottom: 100px;
  `}
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto 0;
  width: 65%;
  ${media.tablet`
    margin-top: 80px;
  `}
  ${media.desktop`
    flex-direction: row;
    margin: 100px auto 0;
    width: 60%;
  `}
`;

const TextContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  ${media.desktop`
    text-align: left;
    width: 60%;
  `}
`;

const DoggoImg = styled.img`
  margin: auto;
  ${media.mobile`
    width: 90%;
  `}
  ${media.desktop`
    margin-right: 0;
    width: calc(100vw * 0.24 - 20px);
  `}
`;

const DoggoImg2 = styled.img`
  margin: 20px auto;
  ${media.mobile`
    width: 90%;
  `}
  ${media.desktop`
    margin-right: 0;
    width: calc(100vw * 0.24 - 20px);
  `}
`;

const Title = styled.p`
  font-family: Metropolis;
  font-weight: bold;
  letter-spacing: 0.05rem;
  margin: 0 auto;
  text-align: center;
  ${media.mobile`
    font-size: 24px;
  `}
  ${media.tablet`
    font-size: 38px;
  `}
  ${media.desktop`
    font-size: 40px;
  `}
`;

const Subtitle = styled(TextSubtitleBold)`
  margin: 0 auto 20px;
  ${media.desktop`
    margin-bottom: 28px;
  `}
  ${media.desktop`
    margin: 0 auto 40px 0;
    width: fit-content;
  `}
`;

const Body = styled(TextBody)`
  margin: 1.5rem auto 0;
`;

const TextLink = styled.a`
  text-decoration: underline;
  &:link {
    color: black;
  }
  &:hover {
    color: gray;
  }
  &:visited {
    color: black;
  }
`;

const ItalicGray = styled.i`
  color: gray;
`;

/* Sub React Components */
function EduText() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  document.getElementsByTagName('i').color = 'gray';
  return (
    <TextContainer>
      {isDesktop && <Subtitle> {eduTitle} </Subtitle>}
      <Body>
        {edu1} <br /> <br /> {edu2}
      </Body>
      <Body>
        {edu3} <ItalicGray> {edu4} </ItalicGray> {edu5}
      </Body>
    </TextContainer>
  );
}

function ArtText() {
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <TextContainer>
      {isDesktop && <Subtitle> {artTitle} </Subtitle>}
      <Body> {art1} </Body>
      <Body>
        {art2} {art3}
      </Body>
      <Body>
        <b>
          <TextLink rel="noopener noreferrer" target="_blank" href="https://exa.market/collection/aldoggo">
            Click here
          </TextLink>
        </b>
        {art4}
      </Body>
    </TextContainer>
  );
}

function EduMobile() {
  return (
    <CardContainer>
      <Subtitle> {eduTitle} </Subtitle>
      <DoggoImg src={EduDoggo} />
      <EduText />
    </CardContainer>
  );
}

function ArtMobile() {
  return (
    <CardContainer>
      <Subtitle> {artTitle} </Subtitle>
      <DoggoImg2 src={ArtDoggo} />
      <ArtText />
    </CardContainer>
  );
}

function EduDesktop() {
  return (
    <CardContainer>
      <EduText />
      <DoggoImg src={EduDoggo} />
    </CardContainer>
  );
}

function ArtDesktop() {
  return (
    <CardContainer>
      <ArtText />
      <DoggoImg src={ArtDoggo} />
    </CardContainer>
  );
}

export default function Meet() {
  const isMobile = useMediaQuery({ maxWidth: 999 });
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <MeetContainer>
      <Title> {meetTitle} </Title>
      {isMobile && <EduMobile />}
      {isMobile && <ArtMobile />}
      {isDesktop && <EduDesktop />}
      {isDesktop && <ArtDesktop />}
    </MeetContainer>
  );
}
