import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { TextTitle } from '../../styles/fonts';

import media from '../../styles/media';
import seeMore from '../../assets/landing-page/see_more.svg';
import { aldoggoCount, aldoggoGenerator } from '../collection/CollectionImporter';
import doggoLink from '../collection/DoggoLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;

  margin: 100px auto 0; // center
  ${media.desktop`
        margin: 280px auto 0;
    `}
`;

const Title = styled(TextTitle)`
  margin: 0;
  width: 100%;
`;

const DoggoGrid = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  max-width: 1000px; // 1.5 * original size
  width: 70%;

  ${media.mobile`
    margin: 30px auto auto;
  `}
  ${media.tablet`
    margin: 60px auto auto;
  `}
  ${media.tablet`
    width: 55%;
  `}
`;

const ImgLink = styled.a`
  margin: 0;
  ${media.mobile`
    width: 50%;
  `}
  ${media.desktop`
    width: 33%;
  `}
`;

const Img = styled.img`
  width: 100%;
`;

const Button = styled.img`
  background-color: white;
  width: 108px;
  ${media.tablet`
    width: 140px;
  `}
  ${media.desktop`
    width: 130px;
  `}
`;

const ButtonLink = styled.a`
  height: fit-content;
  margin: 40px auto 0;
  width: fit-content;
  ${media.desktop`
    margin: 10px calc((100vw - min(1000px, 55vw))/2) 0 auto;
  `}
`;

export default function Doggos() {
  const doggoCards = [];
  const selectedIndices = [];
  const isMobile = useMediaQuery({ maxWidth: 999 });
  const count = isMobile ? 8 : 9;
  const doggoCount = aldoggoCount();
  for (let i = 0; i < count; i += 1) {
    let r = Math.floor(Math.random() * doggoCount);
    while (selectedIndices.includes(r)) {
      r = Math.floor(Math.random() * doggoCount);
    }
    selectedIndices.push(r);
  }

  for (let i = 0; i < count; i += 1) {
    const src = aldoggoGenerator(selectedIndices[i]);
    doggoCards.push(
      <ImgLink rel="noopener noreferrer" target="_blank" href={doggoLink(selectedIndices[i])}>
        <Img src={src} />
      </ImgLink>
    );
  }

  return (
    <Container>
      <Title> Aldoggo Collection </Title>
      <DoggoGrid>{doggoCards}</DoggoGrid>
      <ButtonLink href="/collection">
        {' '}
        <Button src={seeMore} />{' '}
      </ButtonLink>
    </Container>
  );
}
