import React from 'react';
import styled from 'styled-components';
import media from '../../styles/media';
import { TextBody } from '../../styles/fonts';

import pawsImg from '../../assets/about-us/paws.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 160px;
  width: 100vw;

  ${media.tablet`
    margin-bottom: 200px;
  `}
`;

const Img = styled.img`
  margin: 0 auto 80px;
  width: 80px;
  ${media.desktop`
    margin-bottom: 140px; 
    width: 110px;
  `}
`;

const Title = styled.p`
  font-family: Metropolis;
  font-weight: bold;
  letter-spacing: 0.05rem;
  margin: 0 auto 40px; // t l&r b
  text-align: center;
  width: 100%;

  ${media.mobile`
    font-size: 24px;
  `};

  ${media.tablet`
    font-size: 38px;
  `};
`;

const Body = styled(TextBody)`
  margin: 1.5rem auto 0;
  width: 65%;

  ${media.desktop`
    width: 50%;
  `}
`;

const TextLink = styled.a`
  text-decoration: underline;
  &:link {
    color: black;
  }
  &:hover {
    color: gray;
  }
  &:visited {
    color: black;
  }
`;

export default function Algorand() {
  const algoTitle = 'Why Algorand?';
  const algo1 =
    'Algorand is a Layer 1 smart contract blockchain founded by Silvio Micali, a Turing award winner and co-inventor of zero-knowledge proofs.';
  const algo2 =
    'Algorand solved the blockchain trilemma, which is a problem that blockchain must sacrifice at least one of the key properties of security, scalability, and decentralization.';
  const algo3 =
    'With its near zero transaction fees, instant transaction finality, and 5 second block time, Algorand is the best blockchain for user friendly applications.';
  const algo4 = 'If you want to learn more about Algorand features, check out ';
  const algo5 = 'Algorand website.';

  return (
    <Container>
      <Img src={pawsImg} />
      <Title> {algoTitle} </Title>
      <Body> {algo1} </Body>
      <Body> {algo2} </Body>
      <Body> {algo3} </Body>
      <Body>
        {algo4}
        <TextLink rel="noopener noreferrer" target="_blank" href="https://www.algorand.com/technology">
          {algo5}
        </TextLink>
      </Body>
    </Container>
  );
}
