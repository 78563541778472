import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextButton } from '../../styles/fonts';
import media from '../../styles/media';
import { MobileBuy } from './Filter';
import { aldoggoCount, aldoggoGenerator } from './CollectionImporter';
import doggoLink from './DoggoLink';

/* Styled Components */
const DoggoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 0;

  ${media.desktop`
    flex-direction: row; 
    margin-bottom: 280px;
  `}
`;

const DoggoGrid = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 auto auto;
  max-width: 294px;
  position: relative;
  width: 78.5%;

  ${media.tablet`
    max-width: 595px;
    width: 392px; 
  `}

  @media (min-width: 768px) {
    width: 595px;
  }

  ${media.desktop`
    margin: 0 auto auto 30%;
    max-width: 762px;
    width: auto;
  `}

  @media (min-width: 1366px) {
    margin-left: calc((1366px * 0.3) + (100vw - 1366px) / 2);
  }
`;

const DoggoCard = styled.div`
  color: black;
  ${'' /* cursor: default; */}
  height: 171px;
  margin-bottom: 10.5px;
  text-align: center;
  text-decoration: none;
  width: 142px;

  ${media.tablet`
    height: 227px;
    margin-bottom: 14px;
    width: 189px;
  `}

  ${media.desktop`    
    height: 284px;
    margin-bottom: 10px;
    margin-right: 20px;
    width: 234px;
  `}
`;

const DoggoImg = styled.img`
  height: 142px;
  width: 142px;

  ${media.tablet`
    height: 189px;
    width: 189px;
  `}

  ${media.desktop`    
    height: 234px;
    width: 234px;
  `}
`;

const DoggoLabel = styled(TextButton)`
  font-size: 13px;
  height: 13px;

  ${media.tablet`
    font-size: 18px;
    height: 18px;
  `}
`;

/* Sub React Components */
function isIncluded(series, i) {
  if (series === 0) {
    return true;
  }

  const end = 10 * series;
  const start = end - 10;

  return i >= start && i < end;
}

function Doggos({ series }) {
  const doggoCards = [];
  const count = aldoggoCount();
  for (let i = 0; i < count; i += 1) {
    if (isIncluded(series, i + 1)) {
      const src = aldoggoGenerator(i);
      const label = `Aldoggo #${i + 1}`;
      doggoCards.push(
        <DoggoCard key={i} as="a" href={doggoLink(i)} target="_blank">
          <DoggoImg src={src} />
          <DoggoLabel>{label}</DoggoLabel>
        </DoggoCard>
      );
    }
  }

  return (
    <DoggoContainer>
      <DoggoGrid>{doggoCards}</DoggoGrid>
      <MobileBuy />
    </DoggoContainer>
  );
}

Doggos.propTypes = {
  series: PropTypes.number,
};

export default Doggos;
