import styled from 'styled-components';
import Algorand from '../components/about-us/Algorand';
import Footer from '../components/landing-page/Footer';
import Intro from '../components/about-us/Intro';
import Meet from '../components/about-us/Meet';

const Page = styled.div``;

export default function AboutUs() {
  return (
    <Page>
      <Intro />
      <Meet />
      <Algorand />
      <Footer />
    </Page>
  );
}
