import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import media from '../../styles/media';
import { TextTitle } from '../../styles/fonts';

import instagram from '../../assets/landing-page/instagram.svg';
import pawsIcon from '../../assets/landing-page/paws_icon.svg';
import twitter from '../../assets/landing-page/twitter.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 80px auto 140px;
  width: 100vw;

  ${media.tablet`
    margin: 120px auto 200px;
  `}
`;

const Paws = styled.img`
  margin: 0 auto 80px;
  width: 80px;

  ${media.tablet`
    width: 110px;
    margin: 0 auto 120px;
  `}

  ${media.desktop`
    margin: 0 auto 160px;
  `}
`;

const Title = styled(TextTitle)`
  margin: 0 auto; // center
  width: fit-content;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 80px auto 0;
  width: 288px; // 48px: space-between

  ${media.tablet`
    margin: 80px auto 0;
  `}
`;

const SocialImg = styled.img`
  width: 80px;

  ${media.tablet`
    width: 120px;
  `}
`;

export default function FollowUs() {
  // const body_string = "Be first to learn about our launch!"
  // const isMobile = useMediaQuery({maxWidth: 999});
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return (
    <Container>
      <Paws src={pawsIcon} />
      {isDesktop && <Title> Follow Us </Title>}
      {isDesktop && (
        <SocialContainer>
          <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/Aldoggo_Studio">
            <SocialImg src={twitter} />
          </a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aldoggo_studio">
            <SocialImg src={instagram} />
          </a>
        </SocialContainer>
      )}
    </Container>
  );
}
