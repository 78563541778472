import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import OutsideClickHandler from 'react-outside-click-handler';
import Filter from './Filter';
import { TextSubtitleBold, TextSubtitleMedium } from '../../styles/fonts';
import media from '../../styles/media';

/* Assets */
import mobileFilter from '../../assets/collection/mobile_filter.svg';

/* Styled Components */
const HeaderNavbar = styled.nav`
  background-color: white;
  height: 75px;
  position: sticky;
  top: 80px;
  width: 100vw;
  z-index: 5;

  ${media.tablet`
    height: 110px;
  `}

  ${media.desktop`
    top: 85.38px;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 42px;

  ${media.tablet`
    align-items: center;
    flex-direction: row;
    margin-left: 11%;
  `}

  ${media.desktop`
    align-items: stretch;
    flex-direction: column;
    margin-left: 13%;
  `}

  ${media.wide`
    flex-direction: column;
    margin-left: calc((1366px * .13) + (100vw - 1366px) / 2);
    margin-right: auto;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.tablet`
    flex-direction: column;
  `}
`;

const HeaderTitle = styled(TextSubtitleBold)`
  font-size: 24px;
  margin: 27px 0 0;
  text-align: left;

  ${media.tablet`
    font-size: 28px;
    margin: 36px 0 0;
  `}
`;

const Series = styled(TextSubtitleMedium)`
  font-size: 28px;
  margin: 8px 0 0;
  text-align: left;
  width: 266px;
`;

const FilterButton = styled.button`
  background: none;
  border: none;
  height: 36px;
  margin: 22px 42px 0 auto;
  padding: 0;
  width: 36px;

  ${media.tablet`
    margin: 0 11% 0 auto;
  `}
`;

const FilterImg = styled.img``;

/* Sub React Components */
function HeaderMobile({ filter, setFilter }) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (filter) {
          setFilter(!filter);
        }
      }}
    >
      <HeaderContainer>
        <TitleContainer>
          <HeaderTitle>Aldoggo Collection</HeaderTitle>
          <FilterButton onClick={() => setFilter(!filter)}>
            <FilterImg src={mobileFilter} />
          </FilterButton>
        </TitleContainer>
      </HeaderContainer>
    </OutsideClickHandler>
  );
}

function HeaderTablet({ filter, setFilter, series }) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (filter) {
          setFilter(!filter);
        }
      }}
    >
      <HeaderContainer>
        <TitleContainer>
          <HeaderTitle>Aldoggo Collection</HeaderTitle>
          <Series>Series {series === 0 ? 'All' : series}</Series>
        </TitleContainer>
        <FilterButton onClick={() => setFilter(!filter)}>
          <FilterImg src={mobileFilter} />
        </FilterButton>
      </HeaderContainer>
    </OutsideClickHandler>
  );
}

function HeaderDesktop() {
  return (
    <HeaderContainer>
      <HeaderTitle>Aldoggo Collection</HeaderTitle>
    </HeaderContainer>
  );
}

function Header({ series, setSeries }) {
  const [filter, setFilter] = React.useState(false);
  const [unsold, setUnsold] = React.useState(false);

  let isMobile = useMediaQuery({ minWidth: 0 });
  let isTablet = useMediaQuery({ minWidth: 500 });
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  if (isDesktop) {
    isTablet = false;
    isMobile = false;
  } else if (isTablet) {
    isMobile = false;
  }

  return (
    <HeaderNavbar>
      {isMobile && <HeaderMobile filter={filter} setFilter={setFilter} />}
      {isTablet && <HeaderTablet filter={filter} setFilter={setFilter} series={series} />}
      {isDesktop && <HeaderDesktop />}
      <Filter
        filter={isDesktop ? true : filter}
        unsold={unsold}
        setUnsold={setUnsold}
        series={series}
        setSeries={setSeries}
      />
    </HeaderNavbar>
  );
}

/* Prop Types */
Header.propTypes = {
  series: PropTypes.number,
  setSeries: PropTypes.func,
};

HeaderMobile.propTypes = {
  filter: PropTypes.bool,
  setFilter: PropTypes.func,
};

HeaderTablet.propTypes = {
  filter: PropTypes.bool,
  setFilter: PropTypes.func,
  series: PropTypes.number,
};

export default Header;
