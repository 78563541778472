import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';

import mobileAboutUs from '../../assets/navbar/mobile_about_us.svg';
import mobileCollection from '../../assets/navbar/mobile_collection.svg';
import mobileAcademy from '../../assets/navbar/mobile_academy.svg';
import closeImg from '../../assets/navbar/close.svg';

const MenuContainer = styled.nav`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100vw;
  height: 100%;
  /* (r, g, b, opacity) */
  background-color: rgba(51, 51, 51, 0.9);
`;

const MenuImg = styled.img`
  margin-bottom: 5vh;
`;

function comingSoon({ setOpen }) {
  alert('Coming soon!');
  setOpen(false);
}

function MobileMenu({ open, setOpen }) {
  return (
    <MenuContainer open={open}>
      <Link to="/about-us" onClick={() => setOpen(false)}>
        <MenuImg src={mobileAboutUs} />
      </Link>
      <Link to="/collection" onClick={() => setOpen(false)}>
        <MenuImg src={mobileCollection} />
      </Link>
      {/* // Replace once academy page is created */}
      {/* <Link to="/academy" onClick={() => props.setOpen(false) }> */}
      <Link to="#" onClick={() => comingSoon({ open, setOpen })}>
        <MenuImg src={mobileAcademy} />
      </Link>
      <MenuImg src={closeImg} onClick={() => setOpen(false)} />
    </MenuContainer>
  );
}

MobileMenu.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default MobileMenu;
