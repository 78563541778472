import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import media from '../../styles/media';

import aboutUsImg from '../../assets/navbar/about_us.svg';
import academyHoverImg from '../../assets/navbar/academy_hover.svg';
import academyImg from '../../assets/navbar/academy.svg';
import collectionImg from '../../assets/navbar/collection.svg';
import logoImg from '../../assets/navbar/logo.svg';
import openImg from '../../assets/navbar/open.svg';

const LinkContainer = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100vw;

  ${media.desktop`
    height: 85.38px;
    justify-content: flex-start;
  `}
`;

const NavLogo = styled(Link)`
  height: 40%;
  margin-left: 0px;
  position: relative;

  ${media.desktop`
    margin-left: 13%;
    margin-right: 7.11px;
  `}

  ${media.wide`
    margin-left: auto;
  `}
`;

const NavLink = styled(Link)`
  display: none;
  height: 60%;
  margin-left: 14.23px;
  margin-top: 10px;
  position: relative;

  ${media.desktop`
    display: inline;
  `}
`;

const NavImg = styled.img`
  height: 100%;
`;

const AcademyImg = styled(NavImg)`
  display: inline;
`;

const AcademyImgHover = styled(NavImg)`
  display: none;
`;

const AcademyLink = styled.a`
  display: none;
  height: 50%;
  margin-left: auto;
  margin-right: 13%;

  &:hover ${AcademyImg} {
    display: none;
  }

  &:hover ${AcademyImgHover} {
    display: inline;
  }

  ${media.desktop`
    display: inline;
  `}

  ${media.wide`
    display: inline;
    margin-left: 427px;
    margin-right: auto;
  `}
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: in-line;
  height: 30%;
  left: 34.5px;
  padding: 0;
  position: absolute;
`;

function NavLinks({ isMobile, setOpen }) {
  return (
    <LinkContainer>
      {isMobile && (
        <MenuButton onClick={() => setOpen(true)}>
          <NavImg src={openImg} />
        </MenuButton>
      )}
      <NavLogo to="/">
        <NavImg src={logoImg} />
      </NavLogo>
      <NavLink to="/about-us">
        <NavImg src={aboutUsImg} />
      </NavLink>
      <NavLink to="/collection">
        <NavImg src={collectionImg} />
      </NavLink>
      <AcademyLink href="https://aldoggo.academy" target="_blank">
        <AcademyImg src={academyImg} />
        <AcademyImgHover src={academyHoverImg} />
      </AcademyLink>
    </LinkContainer>
  );
}

NavLinks.propTypes = {
  setOpen: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default NavLinks;
