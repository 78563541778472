import styled from 'styled-components';
import Collection from '../components/landing-page/Collection';
import FollowUs from '../components/landing-page/FollowUs';
import Footer from '../components/landing-page/Footer';
import LearnCollect from '../components/landing-page/LearnCollect';
import Intro from '../components/landing-page/Intro';

const Page = styled.div``;

export default function Landing() {
  return (
    <Page>
      <Intro />
      <LearnCollect />
      <Collection />
      <FollowUs />
      <Footer />
    </Page>
  );
}
