import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import media from '../../styles/media';
import { TextTitle, TextBody } from '../../styles/fonts';

import doggoImg from '../../assets/landing-page/welcome_aldoggo.svg';
import doggoImgMobile from '../../assets/landing-page/welcome_aldoggo_mobile.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;

  ${media.desktop`
    flex-direction: row;
  `}
`;

const Img = styled.img`
  margin: 22px 0 0 auto; // align right
  max-width: 400px;
  width: 97%;

  ${media.tablet`
    max-width: 800px;  
    width: 80%;    
  `}

  ${media.desktop`
    margin: 0 0 0 40px; 
    max-width: 1000px;
    width: 45%;    
  `}
`;

const TextContainer = styled.div`
  margin: auto;
  text-align: center;
  width: 270px;

  ${media.tablet`
    width: 450px;
  `}

  ${media.desktop`
    margin: auto 0 auto auto; // align right
  `}
    
  ${media.wide`
    width: 700px; // align right
  `}
`;

const Title = styled(TextTitle)`
  margin: 0 auto 16px;

  ${media.tablet`
     margin: 40px auto 16px;
  `}

  ${media.desktop`
    margin: 0 auto 16px; // center
    width: 100%;
  `}
`;

const Body = styled(TextBody)`
  margin: 0 auto;
  text-align: center;
  width: 100%;
`;

export default function Intro() {
  const title = 'Cutest way to';
  const title2 = 'learn about blockchain';
  const body = 'Aldoggo will guide you through the';
  const body2 = 'Algorand blockchain and NFT';
  const isMobile = useMediaQuery({ maxWidth: 999 });
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return (
    <Container>
      {isMobile && <Img src={doggoImgMobile} />}
      <TextContainer>
        <Title>
          {title} <br /> {title2}
        </Title>
        <Body>
          {body} <br /> {body2}
        </Body>
      </TextContainer>
      {isDesktop && <Img src={doggoImg} />}
    </Container>
  );
}
