import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './MobileMenu';
import NavLinks from './NavLinks';
import setScroll from '../LockScroll';

const NavbarContainer = styled.nav`
  position: sticky;
  top: 0;
  z-index: 10;
`;

function Navbar() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 999 });
  setScroll(open);
  if (open && !isMobile) {
    setOpen(false);
  }

  return (
    <NavbarContainer>
      <NavLinks setOpen={setOpen} isMobile={isMobile} />
      {isMobile && <MobileMenu open={open} setOpen={setOpen} />}
    </NavbarContainer>
  );
}

export default Navbar;
