import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import media from '../../styles/media';
import { TextSubtitleBold, TextBody } from '../../styles/fonts';

import doggoImg from '../../assets/about-us/aldoggo_main.svg';
import starImg from '../../assets/about-us/star.svg';

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 60px;
  width: 100vw;
`;

const DoggoImg = styled.img`
  margin: 0 auto 60px;
  width: 75%;
  ${media.desktop`
    width: 40%;
    max-width: 750px;
  `}
`;

const StarImg = styled.img`
  margin: 40px auto 40px;
  width: 28px;

  ${media.tablet`
    margin: 80px auto 80px;
    width: 40px;
  `}
`;

const Title = styled(TextSubtitleBold)`
  letter-spacing: 0.05rem;
  margin: 0 auto 40px;
  width: 75%;

  ${media.mobile`
    font-size: 24px;
    line-height: 28px;
  `};

  ${media.tablet`
    font-size: 38px;
    line-height: 40px;
  `};

  ${media.desktop`
    font-size: 40px;
    line-height: 44px;
    text-align: left;
    width: 45%;
  `}
`;

const Body = styled(TextBody)`
  margin: 0 auto 1.5em;
  width: 65%;

  ${media.desktop`
    width: 45%;
  `}
`;

export default function Intro() {
  const title1Mobile = 'Aldoggo bridges the';
  const title2Mobile = 'gap between crypto';
  const title3Mobile = 'and non-crypto world';
  const title1 = 'Aldoggo bridges';
  const title2 = 'the gap between crypto';
  const title3 = 'and non-crypto world';
  const title1Desktop = 'Aldoggo bridges the gap between';
  const title2Desktop = 'crypto and non-crypto world';
  const intro1 =
    'Aldoggo Project started as a simple NFT series but soon evolved into an interactive and fun education platform for end users.';
  const intro2 =
    'The biggest obstacle for the mass adoption of blockchain based applications (dApps) is the initial set up process to use dApps.';
  const intro3 =
    'Our goal is to make the process of learning easy and fun so that our beloved friends and families can start interacting with the internet of value, web 3.0.';
  const isMobile = useMediaQuery({ maxWidth: 499 });
  const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 999 });
  const isDesktop = useMediaQuery({ minWidth: 1000 });

  return (
    <IntroContainer>
      <DoggoImg src={doggoImg} />
      {isMobile && (
        <Title>
          {title1Mobile} <br /> {title2Mobile} <br /> {title3Mobile}
        </Title>
      )}
      {isTablet && (
        <Title>
          {title1} <br /> {title2} <br /> {title3}
        </Title>
      )}
      {isDesktop && (
        <Title>
          {title1Desktop} <br /> {title2Desktop}
        </Title>
      )}

      <Body> {intro1} </Body>
      <Body> {intro2} </Body>
      <Body> {intro3} </Body>
      <StarImg src={starImg} />
    </IntroContainer>
  );
}
