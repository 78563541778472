import React from 'react';
import styled from 'styled-components';
import Header from '../components/collection/Header';
import Doggos from '../components/collection/Doggos';
import Footer from '../components/landing-page/Footer';

const Page = styled.div``;

export default function Collection() {
  const [series, setSeries] = React.useState(0);

  return (
    <Page>
      <Header series={series} setSeries={setSeries} />
      <Doggos series={series} />
      <Footer />
    </Page>
  );
}
