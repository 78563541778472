import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import media from '../../styles/media';
import '@fontsource/metropolis';
import { TextTitle } from '../../styles/fonts';

import artAldoggo from '../../assets/landing-page/art_aldoggo.svg';
import eduAldoggo from '../../assets/landing-page/edu_aldoggo.svg';
import artAldoggoMobile from '../../assets/landing-page/art_aldoggo_mobile.svg';
import eduAldoggoMobile from '../../assets/landing-page/edu_aldoggo_mobile.svg';
import downArrow from '../../assets/landing-page/down_arrow.svg';

const Title = styled(TextTitle)`
  margin: 0;
  width: 100%;
`;

const ParentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 60px auto 0;
  width: 100vw;

  ${media.tablet`
    margin: 60px auto 0;
  `}
`;

const TwoCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;

  ${media.desktop`
    flex-direction: row;
    max-width: 1045px;
    margin: 0 auto;
  `}
`;

const LeftCardContainer = styled.div`
  // contains Image (top) + Body (bottom)
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;
  ${media.desktop`
    margin: 40px 60px 40px 0;
    width: fit-content;
  `}
`;

const RightCardContainer = styled.div`
  // contains Image (top) + Body (bottom)
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;

  ${media.desktop`
    margin: 40px 0 40px 60px;
    width: fit-content;
  `}
`;

const ArrowButton = styled.button`
  background-color: white;
  border: 0;
  margin: 0 auto 60px;
  padding: 0;
  width: 24px;

  ${media.tablet`
    margin: 0 auto 120px;
    width: 40px;
  `}

  ${media.desktop`
    width: 50px;
  `}
`;

const Arrow = styled.img`
  width: 100%;
`;

const Image = styled.img`
  src: ${(props) => props.src};
  margin: 0 auto;
  max-width: 500px;
  width: 64%;

  ${media.desktop`
    min-width: 300px;
    width: calc(100vw * 0.25);
  `}
`;

const TextBody = styled.p`
  // override
  font-family: Metropolis;
  font-weight: 400;
  letter-spacing: 0.04rem;
  text-align: left;
  ${media.mobile`
    font-size: 14px;
    line-height: 18px;
  `};

  ${media.tablet`
    font-size: 24px;
    line-height: 36px;
  `};
  ${media.desktop`
    font-size: 20px;
    line-height: 22px;
  `}
`;

const Body = styled(TextBody)`
  margin: 10px auto;
  text-align: center;
  width: 100%;
  ${media.desktop`
    margin: 0px auto;
  `}
`;

const Button = styled.button`
  background-color: white;
  border: 2px solid black;
  border-radius: 50px;
  color: black;
  cursor: pointer;
  font-family: 'Metropolis';
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  letter-spacing: 0.03rem;
  width: 124px;
  ${media.desktop`
    font-size: 18px;
    height: 48px;
    width: 160px;     
  `}
  &:hover {
    background-color: gray;
  }
`;

const ButtonLink = styled.a`
  height: fit-content;
  margin: 40px auto 0;
  width: fit-content;
`;

function scrollToTitle() {
  const obj = document.getElementById('container');
  let curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while (obj === obj.offsetParent);
    window.scroll({
      top: curtop,
      left: 0,
      behavior: 'smooth',
    });
  }
}

export default function LearnCollect() {
  const eduString1 = 'is the first NFT';
  const eduString2 = 'that users will purchase';
  const eduString3 = 'at the end of our courses.';
  const artString1 = 'is an unique NFT';
  const artString2 = 'collection with artistic value for';
  const artString3 = 'Aldoggo supporters.';
  const isMobile = useMediaQuery({ maxWidth: 999 });
  const isDesktop = useMediaQuery({ minWidth: 1000 });
  return (
    <ParentContainer id="container">
      <ArrowButton type="button" onClick={scrollToTitle}>
        <Arrow src={downArrow} />
      </ArrowButton>
      <Title> Learn and Collect </Title>
      <TwoCardsContainer>
        <LeftCardContainer>
          {isMobile && <Image src={eduAldoggoMobile} />}
          {isDesktop && <Image src={eduAldoggo} />}
          <Body>
            <b>Edu Aldoggo </b> {eduString1} <br /> {eduString2} <br /> {eduString3}
          </Body>
        </LeftCardContainer>
        <RightCardContainer>
          {isMobile && <Image src={artAldoggoMobile} />}
          {isDesktop && <Image src={artAldoggo} />}
          <Body>
            <b>Art Aldoggo </b>
            {artString1}
            <br />
            {artString2}
            <br />
            {artString3}
          </Body>
        </RightCardContainer>
      </TwoCardsContainer>
      <ButtonLink href="/about-us">
        <Button> Learn More </Button>
      </ButtonLink>
    </ParentContainer>
  );
}
