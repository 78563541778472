import styled from 'styled-components';
import media from './media';
import '@fontsource/metropolis';

const Metropolis = styled.p`
  font-family: 'Metropolis';
`;

const TextTitle = styled(Metropolis)`
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
  ${media.mobile`
        font-size: 22px;
    `};
  ${media.tablet`
        font-size: 40px;
    `};
  ${media.desktop`
        font-size: 40px;
    `}
`;

const TextSubtitleBold = styled(Metropolis)`
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-align: center;
  ${media.mobile`
        font-size: 20px;
    `};
  ${media.tablet`
        font-size: 36px;
    `};
  ${media.desktop`
        font-size: 28px;
    `}
`;

const TextSubtitleMedium = styled(Metropolis)`
  font-size: 40px;
  font-weight: medium;
`;

const TextBody = styled(Metropolis)`
  font-weight: 400;
  letter-spacing: 0.04rem;
  text-align: left;
  ${media.mobile`
        font-size: 14px;
        line-height: 18px;
    `};
  ${media.tablet`
        font-size: 20px;
        line-height: 24px;
    `};
  ${media.desktop`
        font-size: 20px;
        line-height: 22px;
    `}
`;

const TextButton = styled(Metropolis)`
  font-size: 24px;
  font-weight: bold;
`;

const TextAlert = styled(Metropolis)`
  font-size: 24px;
  font-weight: medium;
`;

export { TextTitle, TextSubtitleBold, TextSubtitleMedium, TextBody, TextButton, TextAlert };
