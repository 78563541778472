import styled from 'styled-components';
import media from '../../styles/media';
import { TextBody } from '../../styles/fonts';

import email from '../../assets/landing-page/email.svg';
import instagram from '../../assets/landing-page/instagram.svg';
import logo from '../../assets/landing-page/logo.svg';
import twitter from '../../assets/landing-page/twitter.svg';

const ParentContainer = styled.div`
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;

  ${media.desktop`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const LeftOrTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  margin: 50px auto 0;
  width: fit-content;
  ${media.desktop`
    justify-content: left;      
    margin: 70px auto 70px 200px;    
  `}
`;

const LogoImg = styled.img`
  height: 45px;
  margin: 0 auto;
  width: 160px;

  ${media.desktop`
    margin: 0;
  `}
`;

const Description = styled(TextBody)`
  height: fit-content;
  margin: 10px 0 0;
  text-align: center;
  width: fit-content;
  ${media.desktop`
    text-align: left;
  `}
`;

const RightOrBottomContainer = styled.div`
  display: flex;
  height: fit-content;
  justify-content: right;
  margin: 50px auto;
  width: fit-content;
  ${media.desktop`
    margin: auto 200px auto auto; // center vertically within footer, fixed margin on right
    text-align: left;
  `}
`;

const SocialImg = styled.img`
  height: 36px;
  padding-left: 20px;
  width: 36px;
  ${media.desktop`
    height: 50px;
    padding-left: 16px;
    width: 50px;    
  `}
`;

export default function Footer() {
  const descString1 = 'Aldoggo bridges the gap between';
  const descString2 = 'crypto and non-crypto world';
  return (
    <ParentContainer>
      <LeftOrTopContainer>
        <LogoImg src={logo} />
        <Description>
          {descString1} <br /> {descString2}{' '}
        </Description>
      </LeftOrTopContainer>
      <RightOrBottomContainer>
        <a href="mailto: aldoggo.nft@gmail.com">
          <SocialImg src={email} />
        </a>
        <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/Aldoggo_Studio">
          <SocialImg src={twitter} />
        </a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aldoggo_studio">
          <SocialImg src={instagram} />
        </a>
      </RightOrBottomContainer>
    </ParentContainer>
  );
}
