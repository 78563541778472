import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextAlert } from '../../styles/fonts';
import media from '../../styles/media';

/* Assets */
import buyHoverImg from '../../assets/collection/buy_hover.svg';
import buyImg from '../../assets/collection/buy.svg';
import dropImg from '../../assets/collection/series_drop.svg';
import upImg from '../../assets/collection/series_up.svg';
import { seriesCount } from './CollectionImporter';

/* Styled Components */
const FilterContainer = styled.div`
  align-items: flex-start;
  background-color: white;
  border-bottom: thin solid #d7d7d7;
  display: ${(props) => (props.filter ? 'flex' : 'none')};
  flex-direction: column;
  height: 120px;
  ${'' /* height: 228px; */}
  margin-top: 17px;
  ${'' /* margin-top: 42px; */}
  width: 100vw;

  ${media.tablet`
    border-color: #D7D7D7;
    border-style: solid;
    border-width: thin; 
    height: 130px;
    ${'' /* height: 225px; */}
    margin: 0;
    padding-top: 11px;
    position: absolute;
    right: 9%;
    top: 80px;
    width: 160px;
  `}

  ${media.desktop`
    border: none;
    height: 285px;
    margin: 0 0 auto 13%;
    padding-top: 40px;
    position: relative;
    right: auto;
    top: auto;
    width: 146px;
  `}
    
  ${media.wide`
    margin-left: calc((1366px * .13) + (100vw - 1366px) / 2);
    margin-right: auto;
  `}
`;

const FilterButton = styled.button`
  background: none;
  border: none;
  color: black;
  padding: 0;
`;

const FilterImg = styled.img``;

const DesktopButton = styled(FilterButton)`
  cursor: pointer;
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

const SeriesText = styled(TextAlert)`
  font-size: 18px;
  margin: 0 auto 3.44px;

  ${media.tablet`
    margin: 5px auto 5px;
  `}
`;

const SeriesButton = styled(FilterButton)`
  cursor: pointer;
  height: 18px;
  margin: 0 38px 12px;

  ${media.tablet`
    height: auto;
    margin: 0 9px 0;  
  `}

  ${media.desktop`
    margin: 0 9px 0 0;
  `}

  ${SeriesText} {
    font-weight: ${(props) => (props.series ? 'bold' : 'medium')};
  }
`;

const SeriesContainer = styled.div`
  align-items: flex-start;
  display: ${(props) => (props.drop ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

const BuyImg = styled(FilterImg)`
  display: inline;
`;

const BuyHoverImg = styled(FilterImg)`
  display: none;
`;

const BuyButton = styled(DesktopButton)`
  margin: 35px auto 0;

  &:hover ${BuyImg} {
    display: none;
  }

  &:hover ${BuyHoverImg} {
    display: inline;
  }
`;

const BuyButtonMobile = styled(BuyButton)`
  display: block;
  margin: 35px auto 35px;

  ${media.desktop`
    display: none;
  `}
`;

/* Sub React Components */
const SERIES = seriesCount() + 1;

export function MobileBuy() {
  return (
    <BuyButtonMobile as="a" href="https://exa.market/collection/aldoggo" target="_blank">
      <BuyImg src={buyImg} />
      <BuyHoverImg src={buyHoverImg} />
    </BuyButtonMobile>
  );
}

export default function Filter({ filter, series, setSeries }) {
  const [drop, setDrop] = React.useState(false);

  const seriesButtons = [];
  for (let i = 0; i < SERIES; i += 1) {
    seriesButtons.push(
      <SeriesButton key={i} series={i === series} onClick={() => setSeries(i)}>
        <SeriesText>Series {i !== 0 ? i : 'All'}</SeriesText>
      </SeriesButton>
    );
  }

  return (
    <FilterContainer filter={filter}>
      <DesktopButton onClick={() => setDrop(!drop)}>
        <FilterImg src={drop ? dropImg : upImg} />
      </DesktopButton>
      <SeriesContainer drop={drop}>{seriesButtons}</SeriesContainer>
      <BuyButton as="a" href="https://exa.market/collection/aldoggo" target="_blank">
        <BuyImg src={buyImg} />
        <BuyHoverImg src={buyHoverImg} />
      </BuyButton>
    </FilterContainer>
  );
}

/* Prop Types */
Filter.propTypes = {
  filter: PropTypes.bool,
  series: PropTypes.number,
  setSeries: PropTypes.func,
};
