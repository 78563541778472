const LINK = 'https://exa.market/asset/';

const doggoAddr = [];

// Series 1
doggoAddr.push(463682532);
doggoAddr.push(463694623);
doggoAddr.push(463859264);
doggoAddr.push(463863544);
doggoAddr.push(463870731);
doggoAddr.push(463883636);
doggoAddr.push(463885295);
doggoAddr.push(464415339);
doggoAddr.push(464416645);

// Series 2
doggoAddr.push(524281175);
doggoAddr.push(524283458);
doggoAddr.push(524285420);
doggoAddr.push(524288729);
doggoAddr.push(524297873);
doggoAddr.push(524300931);
doggoAddr.push(524302319);
doggoAddr.push(524304745);
doggoAddr.push(524306394);
doggoAddr.push(524308280);

// Series 3
doggoAddr.push(564814559);
doggoAddr.push(564819214);
doggoAddr.push(564820027);
doggoAddr.push(564821459);
doggoAddr.push(564822656);
doggoAddr.push(564823629);
doggoAddr.push(564824979);
doggoAddr.push(564826366);
doggoAddr.push(564827604);
doggoAddr.push(564828859);

// Series 4
doggoAddr.push(675809558);
doggoAddr.push(675810824);
doggoAddr.push(675796910);
doggoAddr.push(675798221);
doggoAddr.push(675799730);
doggoAddr.push(690988327);
doggoAddr.push(690989868);
doggoAddr.push(690990866);
doggoAddr.push(690991887);
doggoAddr.push(690992539);

// Series 5
doggoAddr.push(805435745);
doggoAddr.push(805436598);
doggoAddr.push(805441801);
doggoAddr.push(805442336);
doggoAddr.push(805443708);
doggoAddr.push(805444504);
doggoAddr.push(805445438);
doggoAddr.push(805446213);
doggoAddr.push(805446927);
doggoAddr.push(805447423);

export default function doggoLink(i) {
  return LINK + doggoAddr[i];
}
