import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import Landing from './pages/Landing';
import AboutUs from './pages/AboutUs';
import Collection from './pages/Collection';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/collection" element={<Collection />} />
        <Route path="/academy" element={<div />} />
      </Routes>
    </Router>
  );
}
