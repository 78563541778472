// Series 1
import Aldoggo1 from '../../assets/series/01/Aldoggo1.jpeg';
import Aldoggo2 from '../../assets/series/01/Aldoggo2.jpeg';
import Aldoggo3 from '../../assets/series/01/Aldoggo3.jpeg';
import Aldoggo4 from '../../assets/series/01/Aldoggo4.jpeg';
import Aldoggo5 from '../../assets/series/01/Aldoggo5.jpeg';
import Aldoggo6 from '../../assets/series/01/Aldoggo6.jpeg';
import Aldoggo7 from '../../assets/series/01/Aldoggo7.jpeg';
import Aldoggo8 from '../../assets/series/01/Aldoggo8.jpeg';
import Aldoggo9 from '../../assets/series/01/Aldoggo9.jpeg';

// Series 2
import Aldoggo10 from '../../assets/series/02/Aldoggo10.jpeg';
import Aldoggo11 from '../../assets/series/02/Aldoggo11.jpeg';
import Aldoggo12 from '../../assets/series/02/Aldoggo12.jpeg';
import Aldoggo13 from '../../assets/series/02/Aldoggo13.jpeg';
import Aldoggo14 from '../../assets/series/02/Aldoggo14.jpeg';
import Aldoggo15 from '../../assets/series/02/Aldoggo15.jpeg';
import Aldoggo16 from '../../assets/series/02/Aldoggo16.jpeg';
import Aldoggo17 from '../../assets/series/02/Aldoggo17.jpeg';
import Aldoggo18 from '../../assets/series/02/Aldoggo18.jpeg';
import Aldoggo19 from '../../assets/series/02/Aldoggo19.jpeg';

// Series 3
import Aldoggo20 from '../../assets/series/03/Aldoggo20.png';
import Aldoggo21 from '../../assets/series/03/Aldoggo21.png';
import Aldoggo22 from '../../assets/series/03/Aldoggo22.png';
import Aldoggo23 from '../../assets/series/03/Aldoggo23.jpg';
import Aldoggo24 from '../../assets/series/03/Aldoggo24.png';
import Aldoggo25 from '../../assets/series/03/Aldoggo25.jpg';
import Aldoggo26 from '../../assets/series/03/Aldoggo26.png';
import Aldoggo27 from '../../assets/series/03/Aldoggo27.png';
import Aldoggo28 from '../../assets/series/03/Aldoggo28.png';
import Aldoggo29 from '../../assets/series/03/Aldoggo29.png';

// Series 4
import Aldoggo30 from '../../assets/series/04/Aldoggo30.png';
import Aldoggo31 from '../../assets/series/04/Aldoggo31.jpg';
import Aldoggo32 from '../../assets/series/04/Aldoggo32.png';
import Aldoggo33 from '../../assets/series/04/Aldoggo33.jpg';
import Aldoggo34 from '../../assets/series/04/Aldoggo34.jpg';
import Aldoggo35 from '../../assets/series/04/Aldoggo35.jpg';
import Aldoggo36 from '../../assets/series/04/Aldoggo36.jpg';
import Aldoggo37 from '../../assets/series/04/Aldoggo37.jpg';
import Aldoggo38 from '../../assets/series/04/Aldoggo38.png';
import Aldoggo39 from '../../assets/series/04/Aldoggo39.jpg';

// Series 5
import Aldoggo40 from '../../assets/series/05/Aldoggo40.jpg';
import Aldoggo41 from '../../assets/series/05/Aldoggo41.jpg';
import Aldoggo42 from '../../assets/series/05/Aldoggo42.jpg';
import Aldoggo43 from '../../assets/series/05/Aldoggo43.jpg';
import Aldoggo44 from '../../assets/series/05/Aldoggo44.jpg';
import Aldoggo45 from '../../assets/series/05/Aldoggo45.jpg';
import Aldoggo46 from '../../assets/series/05/Aldoggo46.jpg';
import Aldoggo47 from '../../assets/series/05/Aldoggo47.jpg';
import Aldoggo48 from '../../assets/series/05/Aldoggo48.jpg';
import Aldoggo49 from '../../assets/series/05/Aldoggo49.jpg';

const ALDOGGO_LIST = [];

ALDOGGO_LIST.push(Aldoggo1);
ALDOGGO_LIST.push(Aldoggo2);
ALDOGGO_LIST.push(Aldoggo3);
ALDOGGO_LIST.push(Aldoggo4);
ALDOGGO_LIST.push(Aldoggo5);
ALDOGGO_LIST.push(Aldoggo6);
ALDOGGO_LIST.push(Aldoggo7);
ALDOGGO_LIST.push(Aldoggo8);
ALDOGGO_LIST.push(Aldoggo9);

ALDOGGO_LIST.push(Aldoggo10);
ALDOGGO_LIST.push(Aldoggo11);
ALDOGGO_LIST.push(Aldoggo12);
ALDOGGO_LIST.push(Aldoggo13);
ALDOGGO_LIST.push(Aldoggo14);
ALDOGGO_LIST.push(Aldoggo15);
ALDOGGO_LIST.push(Aldoggo16);
ALDOGGO_LIST.push(Aldoggo17);
ALDOGGO_LIST.push(Aldoggo18);
ALDOGGO_LIST.push(Aldoggo19);

ALDOGGO_LIST.push(Aldoggo20);
ALDOGGO_LIST.push(Aldoggo21);
ALDOGGO_LIST.push(Aldoggo22);
ALDOGGO_LIST.push(Aldoggo23);
ALDOGGO_LIST.push(Aldoggo24);
ALDOGGO_LIST.push(Aldoggo25);
ALDOGGO_LIST.push(Aldoggo26);
ALDOGGO_LIST.push(Aldoggo27);
ALDOGGO_LIST.push(Aldoggo28);
ALDOGGO_LIST.push(Aldoggo29);

ALDOGGO_LIST.push(Aldoggo30);
ALDOGGO_LIST.push(Aldoggo31);
ALDOGGO_LIST.push(Aldoggo32);
ALDOGGO_LIST.push(Aldoggo33);
ALDOGGO_LIST.push(Aldoggo34);
ALDOGGO_LIST.push(Aldoggo35);
ALDOGGO_LIST.push(Aldoggo36);
ALDOGGO_LIST.push(Aldoggo37);
ALDOGGO_LIST.push(Aldoggo38);
ALDOGGO_LIST.push(Aldoggo39);

ALDOGGO_LIST.push(Aldoggo40);
ALDOGGO_LIST.push(Aldoggo41);
ALDOGGO_LIST.push(Aldoggo42);
ALDOGGO_LIST.push(Aldoggo43);
ALDOGGO_LIST.push(Aldoggo44);
ALDOGGO_LIST.push(Aldoggo45);
ALDOGGO_LIST.push(Aldoggo46);
ALDOGGO_LIST.push(Aldoggo47);
ALDOGGO_LIST.push(Aldoggo48);
ALDOGGO_LIST.push(Aldoggo49);

export function aldoggoGenerator(i) {
  return ALDOGGO_LIST[i];
}

export function aldoggoCount() {
  return ALDOGGO_LIST.length;
}

export function seriesCount() {
  return Math.ceil(aldoggoCount() / 10);
}
